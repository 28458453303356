<template>
	<div class="main-div" v-if="error == false">
		<ResetPassword v-if="step == 'ResetPassword'" @nextStep="nextStep" />
		<SelectionTrialPeriod v-else-if="step == 'SelectionTrialPeriod'" :user="user" @nextStep="nextStep" />
		<Intro v-else-if="step == 'Intro'" :user="user" @nextStep="nextStep" />
		<QuestionsSociales v-else-if="step == 'QuestionsSociales'" :user="user" @nextStep="nextStep" />
		<Objectives v-else-if="step == 'Objectives'" @nextStep="nextStep" />
		<Confirmation v-else-if="step == 'Confirmation'" :user="user" @nextStep="nextStep" />
		<Finalisation v-else-if="step == 'Finalisation'" :user="user" :formationId="formation._id" />
	</div>
	<Error v-else :error="error"></Error>
</template>

<script>
import Intro from './Intro';
import ResetPassword from '../ResetPassword';
import QuestionsSociales from './QuestionsSociales';
import SelectionTrialPeriod from './SelectionTrialPeriod';
import Objectives from './Objectives';
import Confirmation from './Confirmation';
import Finalisation from './Finalisation';
import { mapGetters } from 'vuex';
import Error from '@/views/layouts/Error';

export default {
	name: 'OnboardingIndex',
	components: {
		Intro,
		SelectionTrialPeriod,
		ResetPassword,
		QuestionsSociales,
		Objectives,
		Confirmation,
		Finalisation,
		Error,
	},
	data() {
		return {
			nextComponent: null,
			step: '',
			formationId: '',
			error: false,
		};
	},
	computed: {
		...mapGetters('accountManager', {
			user: 'user',
			formation: 'formation',
		}),
	},
	async mounted() {
		try {
			await this.$store.dispatch('accountManager/getInfoToOnboardingOrthograph', {
				idUser: this.$route.params.idUser,
			});
			if (this.formation.onboarding_done == true) {
				this.$router.push('/test-positionnement/' + this.user._id + '/' + this.formation._id);
			} else if (
				this.user.subscription_mode &&
				this.user.subscription_mode.substring(0, 3) !== 'sso' &&
				!this.formation.test_trial
			) {
				this.step = 'SelectionTrialPeriod';
			} else {
				if (
					this.user.subscription_mode == 'google' ||
					this.user.subscription_mode == 'facebook' ||
					this.user.has_changed_password == true
				) {
					this.step = 'Intro';
				} else {
					this.step = 'ResetPassword';
				}
			}
		} catch (error) {
			this.error = true;
		}
	},
	methods: {
		nextStep(value) {
			this.step = value;
		},
	},
};
</script>

<style lang="scss" scoped>
.main-div {
	height: 100%;
	position: relative;
}
@media screen and (max-width: 600px) {
	.main-div {
		height: 100%;
	}
}
</style>
