<template>
	<v-row id="finalisation-onboarding" class="mr-0 ml-0">
		<v-col cols="12" md="6" class="col-qs text-center d-flex flex-column justify-center">
			<div class="onboarding-progress-bar-step-div">
				<div class="onboarding-progress-bar-step-sub-div">
					<v-progress-linear
						v-model="stepProgressBarBufferValue"
						:buffer-value="stepProgressBar"
						striped
						rounded
						color="#15c39a"
						height="9"
					></v-progress-linear>
				</div>
			</div>
			<h1 class="mt-4 mt-md-0">
				Finis la <span class="underlineText">personnalisation</span> en l'adaptant à ton niveau
			</h1>

			<p class="mb-10 ortho-text-gray fs25">- Réponds aux <strong>40 questions suivantes</strong></p>
			<p class="ortho-text-gray second-text fs25">- Tu as <strong>30 secondes par questions</strong> ⏳</p>
			<v-btn depressed large dark class="mt-9 mt-md-12 ml-auto mr-auto hvr-grow" width="50%" @click="goToTest()"
				>Commencer</v-btn
			>
		</v-col>
		<v-col cols="12" md="6" class="backgrounded d-none d-md-flex justify-center align-center">
			<img src="@/assets/images/running-girl.png" id="images-qs" alt="questions-sociales" />
		</v-col>
		<Loading :show="loading_show" :label="'C\'est parti !'"></Loading>
	</v-row>
</template>

<script>
import Loading from 'vue-full-loading';

export default {
	name: 'OnboardingFinalisation',
	props: {
		user: Object,
		formationId: String,
	},
	components: {
		Loading,
	},
	data() {
		return {
			stepProgressBar: 100,
			stepProgressBarBufferValue: 70,
			stepProgressBarLimit: 80,

			loading_show: false,
			loading_timeOut: 2000,
		};
	},
	mounted() {
		window.scrollTo(0, 0);

		//start progress bar animation
		this.startBuffer();
	},
	methods: {
		startBuffer() {
			setInterval(() => {
				this.stepProgressBarBufferValue = this.stepProgressBarLimit;
			}, 500);
		},
		goToTest() {
			this.loading_show = true;
			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('onboarding-goToTestPositionnement');
			}
			setTimeout(() => {
				this.loading_show = false;
				this.$router.push('/test-positionnement/' + this.user._id + '/' + this.formationId);
			}, this.loading_timeOut);
		},
	},
};
</script>

<style lang="scss">
.backgrounded {
	background-color: rgba(169, 169, 169, 0.1);
}
#finalisation-onboarding {
	height: 100%;
	.col-qs {
		padding: 2rem 6rem;
		h1 {
			font-size: 40px;
			margin-bottom: 8rem;
		}
		.second-text {
			margin-bottom: 1rem;
		}
	}
	#images-qs {
		width: 25rem;
	}
}
@media screen and (max-width: 600px) {
	#finalisation-onboarding {
		.col-qs {
			padding: 2rem;
			height: 100%;
			h1 {
				font-size: 27px;
				margin-bottom: 2rem;
			}
			.second-text {
				margin-bottom: 2rem;
			}
		}
	}
}
</style>
